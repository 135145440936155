import React from 'react';
import {get, post} from '../../../Model/Network/Config/Axios';
import Lottie from 'react-lottie';
import {handleError, showToast} from '../../Website/Toast/ToastCallback';
import {Input} from '../Survey/Fields/Input';
import {Rating} from '../Survey/Fields/Rating';
import {Select} from '../Survey/Fields/Select';
import {DropDown} from '../Survey/Fields/DropDown';
import {Calendar} from '../Survey/Fields/Calendar';
import {TextArea} from '../Survey/Fields/TextArea';
import {File as FileUpload} from '../Survey/Fields/FileUpload';
import {Modal} from '../../Website/Common/Modal/Modal';

class Index extends React.Component {


	constructor(props) {
		super(props);
		this.state = {
			data: {},
			loading: true,
			forms: this.props.forms ? this.props.forms : [],
			modal:undefined,
            vendors:[]
		}
	}


	componentDidMount() {
		this.getToken()
	}


	getToken = () => {

		let token = this.props.token;
		if (!token)
			this.setState({
				...this.state,
				loading: false,
				error: "Invalid url"
			})
		else {
			get('task.quote', (e, r) => {
				if (r) {
					let errorCode = r.error;
					delete r.error;
					this.setState({
						data: r,
						errorCode,
						loading: false
					})
				} else {
					this.setState({
						error: e.response.data.error,
						loading: false
					})
				}
			}, {token})
		}
	}


	getAllVendors = (id) => {

		let token = this.props.token;
		if (!token)
			this.setState({
				...this.state,
				loading: false,
				error: "Invalid url"
			})
		else {
			get(`task.quote/${id}`, (e, r) => {
				if (r) {
                    this.setState({
                        ...this.state,
                        vendors:r.vendors.map(item=>({
                            value:item.name,
                            key:item.name,
                            id:item.id
                        }))
                    })
				}
			}, {token})
		}
	}

	formSubmit() {
		var anyRequired = undefined
		let forms = {};
		this.state.forms.forEach((item) => {
			let value = this[item.id].getValue();
			if (!value && item.required) {
				anyRequired = item.name;
			} else {
				forms[item.id] = value;
			}
		})
		if (anyRequired) {
			showToast({
				type: 'error',
				message: "Please fill the " + anyRequired
			})
			return;
		}

		let data = {
			forms
		}

		data.token = this.props.token
		post('task.post', data, (e, r) => {
			if (r) {
				this.setState({
					success: true
				})
			} else {
				handleError(e)
			}
		})
	}

	render() {

		if (this.state.success) {
			return (
				<div style={{
					display: 'flex',
					width: '100%',
					height: '100vh',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column'
				}}>
					<Lottie options={{
						loop: true,
						autoplay: true,
						animationData: require('../Survey/Fields/assets/success'),
					}}
							height={250}
							width={250}
					/>
					<p style={{
						fontWeight: 800,
						fontSize: 31
					}}>
						Success
					</p>
					<p style={{
						fontSize: 21,
						marginTop: 12,
						color: "#676767"
					}}>
						{this.state.success}
					</p>
				</div>
			)
		}
		if (this.state.loading) {
			return (
				<div style={{
					display: 'flex',
					width: '100%',
					height: '100vh',
					alignItems: 'center',
					justifyContent: 'center'
				}}>
					<Lottie options={{
						loop: true,
						autoplay: true,
						animationData: require('../Survey/Fields/assets/loading'),
					}}
							height={250}
							width={250}
					/>
				</div>
			)
		}
		let data = this.state.data;

		let base = 0, tax = 0, total = 0;

		data.items.forEach(val => {
			base += val.price * val.qtty;
			tax += ((val.price * val.qtty) * val.taxPct) / 100;
			total += (val.price * val.qtty) + ((val.price * val.qtty) * val.taxPct) / 100;
		})
		return (
			<div>
				{
					this.state.modal?<Modal
						style={{
							save: {
								width: '100%',
								height: '45px',
								display: 'flex',
								justifyContent: 'center',
								textTransform: 'uppercase',
								alignItems: 'center'
							}
						}}
						buttonName="Change Quote"
						des="Please provide the remarks to change the l1 vendor"
						show={this.state.modal} onSave={() => {

					}} title={this.state.modal.name} close={() => {
						this.setState({
							...this.state,
							modal: undefined
						})
					}}>

                    <Select  items={this.state.vendors}
													ref={ref => this.vendor = ref} label={"Select Vendor"}/>
						<Input label="Remarks" placeholder="Enter remarks" ref={ref => this.__remarks = ref}/>
					</Modal>:undefined
				}
				<p style={{
					fontSize: 25,
					fontWeight: 800,
					color: "var(--text-color)"
				}}>
					{
						data.purchaseId
					}&nbsp;&nbsp;
					{
						data.category
					}
				</p>
				<div style={{
					marginTop: 20,
					padding: 25
				}}>
					<p style={{
						color: "#454545",
						fontSize: 17,
						fontWeight: 600,
						marginBottom: 12,
						marginTop: 20
					}}>
						Line Items:
					</p>
					<div style={{display: 'flex', background: "#efefef", border: '1px solid #dedede'}}>
						<div style={{flex: 2, fontSize: 13, padding: 12, borderRight: '1px solid #dedede'}}>
							Details
						</div>
						<div style={{flex: 2, fontSize: 13, padding: 12, borderRight: '1px solid #dedede'}}>
							Submission
						</div>
						<div style={{flex: 1, fontSize: 13, padding: 12, borderRight: '1px solid #dedede'}}>
							Qtty
						</div>
						<div style={{flex: 1, fontSize: 13, padding: 12, borderRight: '1px solid #dedede'}}>
							Price
						</div>

						<div style={{flex: 1, fontSize: 13, padding: 12, borderRight: '1px solid #dedede'}}>
							Tax
						</div>
						<div style={{flex: 1, fontSize: 13, padding: 12}}>
							base
						</div>
						<div style={{flex: 1, fontSize: 13, padding: 12, borderRight: '1px solid #dedede'}}>
							Total
						</div>
					</div>
					{
						this.state.data.items.map(item => {
							return (
								<div key={item.productId} style={{
									display: 'flex',
									background: "#f6f6f6",
									border: '1px solid #dedede'
								}}>
									<div style={{flex: 2, padding: 12, borderRight: '1px solid #dedede'}}>
										<p style={{
											fontSize: 15,
											color: "#454545"
										}}>
											{item.name}
										</p>
										<p style={{fontSize: 12}}>Address: {item.address}</p>
									</div>
									<div style={{flex: 2, padding: 12, borderRight: '1px solid #dedede'}}>
										{
											item.vendorName?
												<div style={{cursor:'pointer'}} onClick={()=>{
													this.setState({
														...this.state,
														modal:item
													})
													this.getAllVendors(item.id)
												}}>
													<p style={{
														fontSize: 15,
														color: "#6367FA",
														fontWeight:600
													}}>
														{item.vendorName}
													</p>
													<p style={{
														fontSize: 15,
														color: "#454545"
													}}>
														{item.vendorPrice}
													</p>
												</div>
												:<>-</>
										}
									</div>
									<div style={{flex: 1, padding: 12, borderRight: '1px solid #dedede'}}>
										<p style={{
											fontSize: 15,
											color: "#454545"
										}}>
											{item.qtty}
										</p>

									</div>
									<div style={{flex: 1, padding: 12, borderRight: '1px solid #dedede'}}>
										{item.price}
									</div>

									<div style={{flex: 1, padding: 12, borderRight: '1px solid #dedede'}}>
										{(item.taxPct * (item.price * item.qtty)) / 100}
									</div>
									<div style={{flex: 1, padding: 12, borderRight: '1px solid #dedede'}}>
										{item.price * item.qtty}
									</div>
									<div style={{flex: 1, padding: 12}}>
										{(item.taxPct * (item.price * item.qtty)) / 100 + (item.price * item.qtty)}
									</div>
								</div>
							)
						})
					}
				</div>

				<div style={{maxWidth: 400, marginLeft: "calc(100% - 400px)"}}>
					<p style={{
						color: "#454545",
						fontSize: 17,
						fontWeight: 600,
						marginTop: 33,
						marginBottom: 12
					}}>
						Total Charges:
					</p>
					<div style={{display: 'flex', background: "#efefef", border: '1px solid #dedede'}}>
						<div style={{
							flex: 3,
							fontSize: 13,
							padding: 12,
							borderRight: '1px solid #dedede'
						}}>
							Base Amount
						</div>
						<div style={{
							flex: 1,
							fontSize: 13,
							padding: 12,
							borderRight: '1px solid #dedede'
						}}>
							{base}
						</div>
					</div>
					<div style={{display: 'flex', background: "#efefef", border: '1px solid #dedede'}}>
						<div style={{
							flex: 3,
							fontSize: 13,
							padding: 12,
							borderRight: '1px solid #dedede'
						}}>
							Tax Amount
						</div>
						<div style={{
							flex: 1,
							fontSize: 13,
							padding: 12,
							borderRight: '1px solid #dedede'
						}}>
							{tax}
						</div>
					</div>

					<div style={{display: 'flex', background: "#efefef", border: '1px solid #dedede'}}>
						<div style={{
							flex: 3,
							fontSize: 13,
							padding: 12,
							borderRight: '1px solid #dedede'
						}}>
							Total Amount
						</div>
						<div style={{
							flex: 1,
							fontSize: 13,
							padding: 12,
							borderRight: '1px solid #dedede'
						}}>
							{total}
						</div>
					</div>

				</div>
				{
					this.state.forms.length > 0 ?
						<div style={{
							padding: 20,
							background: 'white',
							border: '1px solid #dedede',
							width: '100%',
							marginTop: 20,
							boxShadow: '2px 1px 1px rgba(1,1,1,.1)'
						}}>
							<h2 style={{
								fontSize: 25,
								color: "#454545",
								fontWeight: 700
							}}>Custom Fields</h2>
							<p style={{
								marginBottom: 20,
								fontSize: 13,
								color: "#898989",
								marginTop: 4
							}}>Please fill the details below:-</p>
							{
								this.state.forms.map(item => {
									if (item.type === "TEXT") {
										return (
											<Input type="text" key={item.id} ref={ref => this[item.id] = ref}
												   label={item.name} placeholder={item.placeholder}/>
										)
									} else if (item.type === "EMAIL") {
										return (
											<Input type="email" key={item.id} ref={ref => this[item.id] = ref}
												   label={item.name} placeholder={item.placeholder}/>
										)
									} else if (item.type === "NUMBER") {
										return (
											<Input type="number" key={item.id} ref={ref => this[item.id] = ref}
												   label={item.name} placeholder={item.placeholder}/>
										)
									} else if (item.type === "RATING") {
										return (
											<Rating key={item.id} ref={ref => this[item.id] = ref}
													label={item.name}/>
										)
									} else if (item.type === "SELECT" && item.values.length < 5) {
										return (
											<Select key={item.id} items={item.values}
													ref={ref => this[item.id] = ref} label={item.name}/>
										)
									} else if (item.type === "SELECT") {
										let value = item.values.map((item) => {
											item.label = item.key;
											return item
										})
										return (
											<DropDown key={item.id} items={value} ref={ref => this[item.id] = ref}
													  label={item.name}/>
										)
									} else if (item.type === "FILE") {
										return (
											<FileUpload label={item.name} ref={ref => this[item.id] = ref}/>
										)
									} else if (item.type === "DATE") {
										return (
											<Calendar
												className="departure_date"
												title="Return Date"
												label={item.name}
												ref={ref => this[item.id] = ref}

											/>
										)
									} else if (item.type === "TEXTAREA") {
										return (
											<TextArea placeholder={item.name} label={item.name}
													  ref={ref => this[item.id] = ref}/>

										)
									}
									return null

								})
							}
						</div> : undefined
				}


				<Modal
					style={{
						save: {
							width: '100%',
							height: '45px',
							display: 'flex',
							justifyContent: 'center',
							textTransform: 'uppercase',
							alignItems: 'center'
						}
					}}
					buttonName="Cancel Task"
					des="Please provide the remarks to cancel this task"
					show={this.state.cancel} onSave={() => {
					let remarks = this.__remarks.getValue();
					if (!remarks) {
						showToast({
							type: 'error',
							message: "Please enter a valid remarks"
						})
						return;
					}
					let data = {
						remarks
					}

					data.token = this.props.token;
					post('task.cancel', data, (e, r) => {
						if (r) {
							this.setState({
								success: "Task cancelled successfully!!"
							})
						} else {
							handleError(e)
						}
					})
				}} title="Cancel Task" close={() => {
					this.setState({
						...this.state,
						cancel: undefined
					})
				}}>
					<div style={{marginTop: 22}}>
						<Input label="Cancel Remarks" placeholder="Enter remarks" ref={ref => this.__remarks = ref}/>
					</div>
				</Modal>


				<p style={{
					marginTop: 20,
					color: "white",
					padding: 15,
					flex: 1,
					cursor: 'pointer',
					fontWeight: 700,
					textAlign: 'center',
					background: '#6367FA'
				}} onClick={() => {
					this.formSubmit()
				}}>
					Approve Task
				</p>
				<div style={{
					display: 'flex',
					flexDirection: 'row'
				}}>
					<p style={{
						marginTop: 12,
						color: "white",
						padding: 15,
						flex: 1,
						cursor: 'pointer',
						fontWeight: 700,
						textAlign: 'center',
						background: '#fa736b'
					}} onClick={() => {
						this.setState({
							...this.state,
							cancel: true
						})
					}}>
						Decline Task
					</p>

				</div>
			</div>
		)
	}
}

export default Index;
