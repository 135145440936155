import React from 'react'
import Lottie from 'react-lottie';
import { get, post } from "../../../Model/Network/Config/Axios";
import { handleError, showToast } from "../../Website/Toast/ToastCallback";
import Invoice from './Invoice'
import Pr from './Pr';
import Po from './Po';

class Index extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			error: undefined,
			forms: [],
			views: [],
			title: "",
			tab:"basic",
			description: "",
			cancel: undefined
		}
	}

	componentDidMount() {
		this.getToken();
	}

	formSubmit() {
		var anyRequired = undefined
		let forms = {};
		this.state.forms.forEach((item) => {
			let value = this[item.id].getValue();
			if (!value && item.required) {
				anyRequired = item.name;
			} else {
				forms[item.id] = value;
			}
		})
		if (anyRequired) {
			showToast({
				type: 'error',
				message: "Please fill the " + anyRequired
			})
			return;
		}

		let data = {
			forms
		}
		const search = this.props.location.search; // could be '?foo=bar'
		const params = new URLSearchParams(search);
		data.token = params.get('token');
		post('task.post', data, (e, r) => {
			if (r) {
				this.setState({
					success: true
				})
			} else {
				handleError(e)
			}
		})
	}


	getToken = () => {
		const search = this.props.location.search; // could be '?foo=bar'
		const params = new URLSearchParams(search);
		const token = params.get('token');
		if (!token)
			this.setState({
				...this.state,
				loading: false,
				error: "Invalid url"
			})
		else {
			get('task.fetch', (e, r) => {
				if (r) {
					let errorCode = r.error;
					delete r.error;
					this.setState({
						...r,
						errorCode,
						loading: false
					})
				} else {
					// this.setState({
					// 	error: e.response.data.error,
					// 	loading: false
					// })
				}
			}, { token })
		}
	}


	render() {
        const search = this.props.location.search; // could be '?foo=bar'
		const params = new URLSearchParams(search);
		const token = params.get('token');
		if (this.state.success) {
			return (
				<div style={{
					display: 'flex',
					width: '100%',
					height: '100vh',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column'
				}}>
					<Lottie options={{
						loop: true,
						autoplay: true,
						animationData: require('../Survey/Fields/assets/success'),
					}}
						height={250}
						width={250}
					/>
					<p style={{
						fontWeight: 800,
						fontSize: 31
					}}>
						Success
					</p>
					<p style={{
						fontSize: 21,
						marginTop: 12,
						color: "#676767"
					}}>
						{this.state.success}
					</p>
				</div>
			)
		}
		if (this.state.loading) {
			return (
				<div style={{
					display: 'flex',
					width: '100%',
					height: '100vh',
					alignItems: 'center',
					justifyContent: 'center'
				}}>
					<Lottie options={{
						loop: true,
						autoplay: true,
						animationData: require('../Survey/Fields/assets/loading'),
					}}
						height={250}
						width={250}
					/>
				</div>
			)
		}
		if (this.state.error) {
			return (
				<div style={{
					display: 'flex',
					width: '100%',
					height: '100vh',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column'
				}}>
					<Lottie options={{
						loop: true,
						autoplay: true,
						animationData: require('../Survey/Fields/assets/error'),
					}}
						height={250}
						width={250}
					/>
					<p style={{
						fontWeight: 800,
						fontSize: 31
					}}>
						Error while loading task
					</p>
					<p style={{
						fontSize: 21,
						marginTop: 12,
						color: "#676767"
					}}>
						{this.state.error}
					</p>
				</div>
			)
		}


		return (
			<div id="product-page">
				<div style={{ borderBottom: '2px dashed var(--primary-color)' }} className="ptb-70 ptb-40 dotted">
					<div className="container-size">
						<div>
							<h1 style={{ marginTop: 20 }} className="integration-title">{this.state.title}</h1>
							<p className="integration-desc">{this.state.description}</p>
						</div>
					</div>
				</div>
				<div style={{ background: 'var(--background-color)' }} className="ptb-70 ptb-40">
					<div className="container-size">
						<div className="flex-wrap">
							<div style={{
								padding: 20,
								background: 'white',
								border: '1px solid #dedede',
								width: '100%',
								boxShadow: '2px 1px 1px rgba(1,1,1,.1)'
							}}>
                                {
                                    this.state.details.type === 'invoice' ?
                                   	 <Invoice 
                                        token={token} 
                                        forms={this.state.forms}
                                        
                                        />
                                    : this.state.details.type === 'pr' ?
									<Pr 
									  token={token} 
									  forms={this.state.forms}


									/>: this.state.details.type === 'po' ?
									<Po 
										token={token}
										forms={this.state.forms}
									/>:null
                                }
                            </div>
								
						</div>
                     </div>
                </div>
			</div>
		)
	}

}

export default Index
