import React from 'react';
import { Header } from '../Common/Header';
import { Footer } from '../Common/Footer';
import './index.css';
import { InputForm } from '../Common/Input';
import MapWrapper from '../Common/MapWrapper';
import axios from 'axios';


class Contact extends React.Component {

    state = {
        name: '',
        email: '',
        cname: '',
        desig: '',
        mobilenumber: ''
    }

    get() {

        let name = this.state.name;
        let email = this.state.email;
        let cname = this.state.cname;
        let desig = this.state.desig;
        let mobilenumber = this.state.mobilenumber;

        if (!name) {
            alert("Please enter the name");
            return;
        }

        if (!email) {
            alert("Please enter the email");
            return;
        }

        if (!cname) {
            alert("Please enter the company Name");
            return;
        }

        if (!desig) {
            alert("Please enter the Designation");
            return;
        }

        if (!mobilenumber) {
            alert("Please enter your mobile number");
            return;
        }

        axios({
            method: 'post',
            url: `https://www.rohitsindher.com/contactusapi/send-email`,
            data: {
                to: '',
                name: name,
                email: email,
                subject: `Schedule Demo Form 
                Company Name : ${cname} designation: ${desig}`,
                message: ` Phone : ${mobilenumber}`
            }
        })
            .then(res => {
                alert("your form is succesfully submit");
                this.setState({
                    name: '',
                    email: '',
                    cname: '',
                    desig: '',
                    mobilenumber: ''

                })
            })
    }
    render() {
        return (
            <div id="contact">
                <Header />
                <div style={{ borderBottom: '2px dashed var(--primary-color)' }} className="pt-70 pt-40 dotted">
                    <div className="container-size">
                        <div className="contact-title flex-h-center flex-v-center">
                            <h1>Contact Us</h1>
                        </div>
                    </div>
                </div>
                <div style={{ backgroundColor: 'var(--background-color' }} className="ptb-70 ptb-40">
                    <div className="container-size">


                        <div className="flex-wrap">
                            <div className="col-md-6 col-xs-12 ">
                                <div style={{ width: '100%', position: 'relative' }}>
                                    <MapWrapper />
                                </div>
                                <div style={{ marginTop: 220 }} className="contact-address-layout">
                                    <div className="contact-address">
                                        <h1 style={{ fontSize: 20, textTransform: 'uppercase', marginBottom: 10 }} className="font-700">Postal address</h1>
                                        <p><span style={{ fontWeight: 600 }}>E:</span> hello@antrepriz.com</p>
                                        <p><span style={{ fontWeight: 600 }}>A:</span> 209 1B/1A, 3rd Floor, Timeless Building, Range Hill Rd, opp. Symphony Hotel, Shivajinagar, Pune, Maharashtra 411020</p>
                                    </div>
                                </div>



                                <div className="flex-wrap support-address-layout">

                                    <div className="col-md-6">
                                        <div className="support-address">
                                            <h1 style={{ fontSize: 20, textTransform: 'uppercase', marginBottom: 10 }} className="font-700">Travel Support</h1>
                                            <p><span style={{ fontWeight: 600 }}>E:</span> hello@antrepriz.com</p>
                                            <p><span style={{ fontWeight: 600 }}>M:</span> 9354356088</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="support-address support-address-second">
                                            <h1 style={{ fontSize: 20, textTransform: 'uppercase', marginBottom: 10 }} className="font-700">Expense Support</h1>
                                            <p><span style={{ fontWeight: 600 }}>E:</span> hello@antrepriz.com</p>
                                            <p><span style={{ fontWeight: 600 }}>M:</span> 8448239151</p>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="support-address">
                                            <h1 style={{ fontSize: 20, textTransform: 'uppercase', marginBottom: 10 }} className="font-700">Dice Support New Number</h1>
                                            <p><span style={{ fontWeight: 600 }}>M:</span> 9513631690</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                       
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xs-12 flex-h-center">
                                <div style={{ flexDirection: 'column' }} className="contact-form-layout flex-v-center">
                                    <InputForm value={this.state.name} onChange={e => {
                                        this.setState({
                                            name: e.target.value
                                        })
                                    }} title="Name" />
                                    <InputForm value={this.state.email} onChange={(e) => {
                                        this.setState({
                                            email: e.target.value
                                        })
                                    }} type="text" title="Email" />
                                    <InputForm value={this.state.cname} onChange={e => {
                                        this.setState({
                                            cname: e.target.value
                                        })
                                    }} type="text" title="Company Name" />
                                    <InputForm value={this.state.desig} onChange={e => {
                                        this.setState({
                                            desig: e.target.value
                                        })
                                    }} type="text" title="Designation" />
                                    <InputForm value={this.state.mobilenumber} onChange={e => {
                                        this.setState({
                                            mobilenumber: e.target.value
                                        })
                                    }} type="number" title="Mobile Number" />
                                    <div onClick={() => this.get()} className="btn">Submit Form</div>
                                </div>
                            </div>
                        </div>
                        <div style={{ marginTop: 200 }} />
                    </div>
                </div>
                <Footer />

            </div>
        );
    }
}

export default Contact;
